<template>
  <div>
    <header-hosting></header-hosting>
    <component-description-service
        title="Mais sobre o serviço Web Hosting"
        description="Nosso serviço de Hosting foi desenvolvido para
        desempenho em cima da nossa infraestrutura de Cloud com várias otimizações de velocidade personalizadas"
        opt1="Disponibilidade"
        desc-opt1="Inclui SLA com disponibilidade de 99,995%, e a possibilidade de definir soluções de redundância à medida"
        opt2="CMS"
        desc-opt2="Todos os nossos planos incluem instalação do WordPress e outros CMS."
        opt3="Segurança"
        desc-opt3="Por padrão, clientes particulares têm protecção/segurança de mais alto nível com soluções de firewall NetSpace"
    ></component-description-service>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderHosting from "../../components/cloud/hosting/HeaderHosting";
import ContactFormService from "../../components/tools/ContactFormService";
import ComponentDescriptionService from "../../components/common/ComponentDescriptionService";
export default {
  name: "PageHosting",
  components: {ComponentDescriptionService, ContactFormService, HeaderHosting },
};
</script>

<style scoped></style>
