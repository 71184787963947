<template>
<div>
  <header-sip></header-sip>
  <more-sip></more-sip>
  <contact-form-service></contact-form-service>
</div>
</template>

<script>
import HeaderSip from "../components/voice/sip/HeaderSip";
import MoreSip from "../components/voice/sip/MoreSip";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageSipTrunk",
  components: {ContactFormService, MoreSip, HeaderSip},
  metaInfo: {
    title: "SIP - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "SIP - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "serviços de voz vocacionada para clientes com necessidades de interligação de centrais telefónicas por tecnologia IP.",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
}
</script>

<style scoped>

</style>