<template>
<div class="m-txt-center m-center m-top-30">
<v-card width="350" class="m-txt-center m-center" elevation="0">
  <v-card-text>
    <img
        @click="redirectHome"
        style="max-width: 80px; cursor: pointer"
        alt=""
        :src="require('../../../assets/img/logo_.png')"
    />
  </v-card-text>

    <v-form ref="form" v-model="valid" novalidate id="formReq">
      <v-row>
        <v-col cols="12">
          <v-text-field
              filled
              label="Introduza o número randómico"
              dense
              v-model="rand"
              name="rand"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-text-field
                filled
                label="Introduza o access"
                dense
                v-model="email"
                name="email"
            ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
              filled
              label="Preencha o resultado da imagem"
              dense
              v-model="captcha"
              :rules="captchaRules"
          ></v-text-field>
        </v-col>
        <v-card-text id="n-reload"> </v-card-text>

        <input type="hidden" name="device" :value="service" />

        <v-card-text style="margin-top: -50px">
          <v-btn
              class="m-btn-effect m-btn-defaul-border m-txt-center"
              text
              outlined
              block
              color="rgb(23, 87, 109)"
              style="text-transform: capitalize"
              @click="requestRandom()"
              :disabled="!valid"
          >
            Submeter
          </v-btn>
        </v-card-text>
      </v-row>
    </v-form>
</v-card>
  <spin></spin>
</div>
</template>

<script>
import { NextReload } from "../../../services/nextReload";
import Vue from "vue";
import Spin from "../../tools/Spin";

export default {
  name: "Reference",
  components: {Spin},
  data: () => ({
    text: "",
    email: "",
    service: "web",
    valid: true,
    rand: "",
    randRules: [
      (v) => !!v || "Nome da empresa é requerido",
      (v) => (v && v.length >= 3) || "Empresa deve ter pelomenos 3 caracteres",
    ],
    emailRules: [
      (v) => !!v || "Access é requerido",
      (v) => /.+@.+\..+/.test(v) || "Access deve ser válido",
    ],
    captcha: "",
    captchaRules: [(v) => !!v || "Captcha é requerido"],
  }),
  methods:{
    redirectHome() {
      return this.$router.push("/");
    },

    nextReaload() {
      let next;
      next = new NextReload();
      return next.render();
    },
    validate() {
      return this.$refs.form.validate();
    },
    requestRandom() {
      document.getElementById("n-reload").innerHTML = "";
      if (this.$store.getters.getNs === this.captcha) {
        if (this.validate()) {
          let Form = document.getElementById("formReq");
          let formData = new FormData(Form);
          let data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, { [key]: val });
          }
          return this.$store.dispatch("requestRandom", data);
        } else {
          document.getElementById("n-reload").innerHTML = "";
          Vue.toasted.error(
              "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
              {
                theme: "bubble",
                position: "top-center",
                duration: 5000,
              }
          );
          this.nextReaload();
        }
      }
      Vue.toasted.error(
          "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
          {
            theme: "bubble",
            position: "top-center",
            duration: 5000,
          }
      );
      document.getElementById("n-reload").innerHTML = "";
      this.nextReaload();
    },
  },

  mounted() {
    return this.nextReaload();
  },
}
</script>

<style scoped>

</style>