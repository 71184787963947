<template>
  <div class="filter m-border-line-1">
    <v-container>
      <v-card
        width="320"
        class="transparent"
        elevation="0"
        style="padding-top: 100px !important"
      >
        <h1 class="">
          <span class="mx-auto text-center text-header white--text text-left"
            >Internet para todos e para tudo.</span
          >
        </h1>
      </v-card>

      <v-card-text
        style="width: 400px; font-size: 20px !important;"
        class="mt-12 white--text text-subtitle-1 text-sub-header margin-line font-weight-regular mx-n4"
      >
        Oferecemos um serviço de Internet fiável e seguro, de alta velocidade terminado em tecnologia
        de fibra até o equipamento do Cliente.
      </v-card-text>
      <v-row>
<!--        <v-col cols="2" sm="6" md="4" class="ml-0" style="width: 250px">-->
<!--          <v-text-field label="E-mail" filled>-->
<!--            <template v-slot:label>-->
<!--              <label-->
<!--                class="white&#45;&#45;text text-capitalize"-->
<!--                style="-->
<!--                  font-size: 16px;-->
<!--                  text-transform: capitalize;-->
<!--                  font-family: 'Fira Sans Condensed', sans-serif;-->
<!--                "-->
<!--              >-->
<!--                Escreva o teu melhor contacto. Nós ligamos!-->
<!--              </label>-->
<!--            </template>-->
<!--          </v-text-field>-->
<!--        </v-col>-->
        <v-col cols="6" sm="6" md="3">
          <div class="mt-10">
            <v-btn
              x-large
              outlined
              color="white"
              dark
              @click="callMe"
              class="text-capitalize"
              >Quero ser contactado
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import eventBus from "../../../main";

export default {
  name: "HeaderContent",
  methods: {
    callMe() {
      return eventBus.$emit("callMe", true);
    },
  },
};
</script>

<style scoped>
/*fb8c00*/
.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}
.filter {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgb(0 0 0) 35%,
    #1b92ac 100%
  ) !important;
  opacity: 0.7;
  margin-top: -200px;
  /*height: 634px;*/
  max-height: 700px;
  height: 630px;
}

.v-application .primary--text {
  color: white !important;
  caret-color: white !important;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff !important;
  outline: white !important;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff !important;
  color: #ffffff !important;
  outline: white !important;
}

.v-text-field .v-label {
  font-size: 13px !important;
  outline: white !important;
}
.v-input input:focus,
v-input input:active {
  outline: white !important;
}

.theme--light.v-input >>> input {
  color: #ffffff !important;
  outline: white !important;
}

.theme--light.v-input >>> textarea {
  color: #ffffff !important;
  outline: white !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #ffffff !important;
}

.v-text-field--outlined .v-label {
  color: white;
}

.theme--light.v-text-field >>> .v-input__control > .v-input__slot:before {
  border-color: #ffffff !important;
  color: white;
}

/**
Background input
 */
.theme--light.v-text-field--filled >>> .v-input__control > .v-input__slot {
  background: #81873114 !important;
}
</style>
