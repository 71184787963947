<template>
  <v-app>
    <div
      class=""
      style="
        height: 40px;
        background-color: #2d2b2b;
        color: #f6f6f6;
        width: 100%;
        position: fixed;
        z-index: 100;
      "
    >
      <v-container style="padding-left: 130px" fluid>
        <div>
          <!--    MENU PHONE -->
          <v-menu
            class="d-md-none"
            bottom
            max-width="300"
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                v-bind="attrs"
                v-on="on"
                class="d-md-none"
                style="float: right; color: white; margin-top: -8px"
              ></v-app-bar-nav-icon>
            </template>

            <v-list>


              <v-list-item @click="myAccount">
                <v-list-item-title
                >Cliente
                  <v-icon class="text-left" style="float: left"
                  >mdi-chevron-right</v-icon
                  >
                </v-list-item-title
                >
              </v-list-item>

              <v-list-item @click="particular">
                <v-list-item-title
                  >Particular
                  <v-icon class="text-left" style="float: left"
                  >mdi-chevron-right</v-icon
                  ></v-list-item-title
                >
              </v-list-item>

              <v-list-item @click="enterprise">
                <v-list-item-title
                  >Empresa
                  <v-icon class="text-left" style="float: left"
                  >mdi-chevron-right</v-icon
                  >
                </v-list-item-title
                >
              </v-list-item>

              <v-list-item @click="institutional">
                <v-list-item-title
                  >Institucional
                  <v-icon class="text-left" style="float: left"
                  >mdi-chevron-right</v-icon
                  >
                </v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <!--          <v-app-bar-nav-icon style="float: right; color: white; margin-top: -8px"></v-app-bar-nav-icon>-->
          <v-btn
            class="text-capitalize mt-n3 font-weight-regular hidden-sm-and-down"
            color="white"
            text
            @click="particular"
          >
            Particular
          </v-btn>
          <v-btn
            class="text-capitalize mt-n3 mx-1 font-weight-regular hidden-sm-and-down"
            color="white"
            text
            @click="enterprise"
          >
            Empresa
          </v-btn>

          <v-btn
            class="text-capitalize mt-n3 mx-1 font-weight-regular hidden-sm-and-down"
            color="white"
            text
            @click="institutional"
          >
            Institucional
          </v-btn>
        </div>
      </v-container>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "PrimaryMenu",
  methods: {
    particular() {
      return this.$store.dispatch("particular");
    },
    enterprise() {
      return this.$store.dispatch("enterprise");
    },
    institutional() {
      return this.$store.dispatch("institutional");
    },
    myAccount() {
      return this.$store.dispatch("myAccount");
    },
  },
};
</script>

<style scoped></style>
