<template>
  <div class="bg-header-inst" style="height: 400px; margin-top: -738px">
    <v-container>
      <v-card
        width="320"
        class="transparent"
        elevation="0"
        style="padding-top: 70px !important"
      >
        <h1 class="">
          <span class="mx-auto text-center text-header white--text text-left"
            >Conheça o produto <strong>IP PBX Virtual</strong></span
          >
        </h1>
      </v-card>

      <v-card-text
        style="width: 400px; font-size: 20px !important"
        class="mt-12 white--text text-subtitle-1 text-sub-header margin-line font-weight-regular mx-n4"
      >
        Serviço com recursos de última geração, totalmente online em cloud
        computing e com a praticidade que se precisa.
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeaderPbx",
};
</script>

<style scoped>
strong {
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}
.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}
.bg-sec-support-e {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}

.bg-header-inst {
  background-image: linear-gradient(
      295deg,
      rgba(15, 15, 16, 0.68),
      rgb(0, 0, 0)
    ),
    url("https://img.freepik.com/free-photo/black-working-father-making-conference-call-while-son-is-sitting-his-lap-home_637285-11497.jpg");
  background-size: cover;
  background-color: #efefef;
  background-position: 0;
}
</style>
