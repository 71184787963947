<template>
  <div>
    <header-net-fast></header-net-fast>
    <more-net-fast></more-net-fast>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderNetFast from "../components/internet/netfast/HeaderNetFast";
import MoreNetFast from "../components/internet/netfast/MoreNetFast";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetFast",
  components: { ContactFormService, MoreNetFast, HeaderNetFast },
  metaInfo: {
    title: "NETFAST - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "NETFAST - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Serviço de Internet fiável e seguro, de alta velocidade terminado em tecnologia de fibra até o equipamento do Cliente",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
