<template>
  <div class="">
    <v-app-bar
      app
      fixed
      style="margin-top: 40px; padding-left: 130px; background-color: white"
      height="80"
    >
      <img
        @click="redirectHome"
        style="max-width: 80px; cursor: pointer"
        alt=""
        :src="require('../../assets/img/logo_.png')"
      />
      <div style="padding-left: 15px; padding-right: 15px"></div>
      <v-btn
        class="text-capitalize font-weight-bold btn-hover hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        @click.stop="showServiceMenu"
      >
        Serviços
      </v-btn>

      <v-btn
        class="text-capitalize font-weight-bold hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        @click.stop="showProductMenu"
      >
        Planos
      </v-btn>
      <v-btn
        class="text-capitalize font-weight-bold hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        style="text-transform: none !important"
        @click="$vuetify.goTo(target, options)"
      >
        Apoio ao Cliente
      </v-btn>
      <v-spacer></v-spacer>
      <div
        style="padding-right: 65px"
        class="d-sm-none d-md-flex hidden-sm-and-down"
      >
        <v-menu
            class="d-md-none"
            bottom
            max-width="300"
            origin="center center"
            transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
<!--            <v-app-bar-nav-icon-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--                class="d-md-none"-->
<!--                style="float: right; color: white; margin-top: -8px"-->
<!--            ></v-app-bar-nav-icon>-->


            <v-btn
                class="text-capitalize font-weight-bold"
                color="black"
                v-bind="attrs"
                v-on="on"
                text
                x-large
            >
              <v-icon> mdi-account </v-icon>
              <span>Cliente</span>
            </v-btn>

          </template>

          <v-list>


            <v-list-item @click="myAccount">
              <v-list-item-title
              >Portal
                <v-icon class="text-left" style="float: left"
                >mdi-chevron-right</v-icon
                >
              </v-list-item-title
              >
            </v-list-item>

            <v-list-item @click="dashboard">
              <v-list-item-title
              >Dashboard
                <v-icon class="text-left" style="float: left"
                >mdi-chevron-right</v-icon
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
<!--        <v-btn-->
<!--          class="text-capitalize font-weight-bold"-->
<!--          color="black"-->
<!--          text-->
<!--          x-large-->
<!--          @click="myAccount"-->
<!--        >-->
<!--          <v-icon> mdi-account </v-icon>-->
<!--          <span>Cliente</span>-->
<!--        </v-btn>-->
      </div>
    </v-app-bar>

    <!--    DRAWER Services-->
    <service-menu></service-menu>
    <product-menu></product-menu>
  </div>
</template>

<script>
import eventBus from "../../main";
import ServiceMenu from "./ServiceMenu";
import ProductMenu from "./ProductMenu";
export default {
  name: "SecundaryMenu",
  components: { ProductMenu, ServiceMenu },
  data: () => ({
    selector: "#contact",
    duration: 900,
    offset: 0,
    easing: "easeInOutCubic",
    drawer: false,
    drawerProduct: false,
    // group: null,
  }),
  methods: {
    myAccount() {
      return this.$store.dispatch("myAccount");
    },
    dashboard() {
      return this.$store.dispatch("dashboard");
    },
    redirectHome() {
      return this.$router.push("/");
    },
    showServiceMenu() {
      this.drawer = true;
      eventBus.$emit("serviceMenu", this.drawer);
    },
    showProductMenu() {
      this.drawerProduct = true;
      eventBus.$emit("productMenu", this.drawerProduct);
    },
  },
  computed: {
    target() {
      return this.selector;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  // watch: {
  //   group () {
  //     this.drawer = false
  //   },
  // },
};
</script>

<style scoped>
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.theme--light.v-btn:hover::after {
  opacity: 0;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 0;
}
.v-ripple_container {
}
</style>
