<template>
  <div class="mb-12 bg-ef" style="width: 100%">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important"
            >Comunique-se com as nossas Soluções de Voz</strong
          >
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Receba e faça chamadas a custo baixo
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="sans spoiler-size call-wid">
              <strong>VOIP</strong>
            </div>
            <v-card-text
              class="spoiler-description call-wid"
              style="overflow-wrap: break-word !important"
            >
              Serviço de Voz de alta Qualidade de ponta a ponta, que permite ao
              cliente empresarial ou particular fazer e receber chamadas a um
              custo baixo.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div class="">
                <v-btn @click="voip" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>SIP Trunk</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              serviços de voz vocacionada para clientes com
              necessidades de interligação de centrais telefónicas por
              tecnologia IP.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="sipTrunk" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>IP PBX Virtual</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Serviço com recursos de última geração,
              totalmente online em cloud computing e com a praticidade que se precisa.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="ipPbx" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="m-top-30"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionThirdPlan",
  methods: {
    voip() {
      return this.$router.push({ name: "enterprise.voip" });
    },
    sipTrunk() {
      return this.$router.push({ name: "enterprise.sip.trunk" });
    },
    ipPbx() {
      return this.$router.push({ name: "enterprise.pbx" });
    },
  },
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}
.draw-svg {
  background-image: linear-gradient(
      rgba(15, 15, 16, 0.5),
      rgb(23, 87, 109),
      rgb(0, 0, 0)
    ),
    url("https://img.freepik.com/free-photo/smiling-african-american-businessman-talking-phone-with-newspaper-cup-coffee-office_231208-757.jpg");
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  background-position: 30% 75%;
  background-size: cover;

}
.office-svg {
  background-image: linear-gradient(
      rgba(15, 15, 16, 0.5),
      rgb(23, 87, 109),
      rgb(0, 0, 0)
    ),
    url("https://img.freepik.com/free-photo/black-man-giving-presentation-meeting_23-2149370611.jpg");
  background-size: cover;

}

.ofmail-svg {
  background-image: linear-gradient(
      rgba(15, 15, 16, 0.5),
      rgb(23, 87, 109),
      rgb(0, 0, 0)
    ),
    url("https://img.freepik.com/free-photo/young-woman-holding-tablet-work_23-2149116576.jpg");
  background-size: cover;
}
.bg-ef {
  background-color: #efefef;
}
</style>
