import axios from "axios";
import router from "../router";

const apiClient = axios.create({
  baseURL: "https://application.netspace.ao",
  withCredentials: false,
  validateStatus: () => true,
  headers: {
    Accept: "Application/json",
    "Content-Type": "Application/json",
  },
});

export default {

    customizeHeader(token) {
        return (apiClient.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${token}`);
    },
    interceptionRoute() {
        return apiClient.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    return router.push({ name: "particular" }).catch.catch(() => {
                        throw new Error("Problem - Contact DELMAWAVE");
                    });
                }
                return Promise.reject(error);
            }
        );
    },

  requestCallToNetSpace(options) {
    return apiClient.post("/api/v1/request/call", options);
  },

  requestInfoOfService(options) {
    return apiClient.post("/api/v1/request/info/service", options);
  },

    requestRandomOfService(options) {
        return apiClient.post("/api/v1/request/random/service", options);
    },
    requestChargeService(options) {
        return apiClient.post("/api/v1/request/random/charge", options);
    },
};
