<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="nameRules"
                    label="Escreva o seu nome"
                    required
                    v-model="name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :rules="emailRules"
                    label="Email"
                    required
                    v-model="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :rules="phoneRules"
                    label="Telefone|Móvel"
                    required
                    v-model="phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="[
                      'Internet',
                      'Telefonia',
                      'Ciber Segurança',
                      'Cloud',
                    ]"
                    label="Interesses (Serviços)"
                    multiple
                    v-model="opt"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <!--          <small>*indicates required field</small>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="rgb(23, 87, 109)" text @click="onCloseModal">
            Fechar
          </v-btn>
          <v-btn color="rgb(23, 87, 109)" text @click="submitCallMe">
            Concluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "CallMe",
  data: () => ({
    dialog: false,
    valid: true,
    name: "",
    email: "",
    phone: "",
    opt: "",
    nameRules: [
      (v) => v.length <= 60 || "Máximo 10 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    phoneRules: [
      (v) => v.length <= 9 || "Máximo 9 caracteres",
      (v) => /^\d+$/.test(v) || "Campo obrigatório",
    ],
    emailRules: [
      (v) => v.trim().split(" ").length <= 30 || "Máximo 30 caracteres",
      (v) => /.+@.+\..+/.test(v) || "Correio inválido",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
    onCloseModal() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    submitCallMe() {
      if (this.$refs.form.validate()) {
        const payload = {
          title: "Pedido de informação vindo do website",
          subject: "Pedido de Informação: Origem - Website",
          message:
            "Um potencial cliente de nome " +
            this.name.toUpperCase() +
            " que tem o e-mail " +
            this.email +
            "  e número de telefone " +
            this.phone +
            " pretende que lhe contactem." +
            (this.opt ? " Possíveis interesses: " + this.opt : ""),
        };

        this.$store.dispatch("requestCallToNetSpace", payload);
        this.$refs.form.reset();
      }
    },
  },
  created() {
    const self = this;
    eventBus.$on("callMe", function (payload) {
      self.dialog = payload;
    });
  },
};
</script>

<style scoped></style>
