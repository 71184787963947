<template>
  <div>
    <component-banner-service
      title="Conheça o serviço"
      bold-title="Cloud Database"
      description="Crie recursos essenciais de computação em núvem como armazenamento e rede sob demanda."
      :my-style="dt"
    ></component-banner-service>
    <component-description-service
      title="Mais sobre o serviço Cloud Database"
      description="Instancie Banco de dados para atender às suas necessidades, sejam elas de baixa ou larga escala."
      opt1="Custo"
      desc-opt1="Redução do tempo e o custo de provisionamento e escalonamento em ambientes de teste e produção."
      opt2="Escalabilidade"
      desc-opt2="Amplie ou reduza o seu cluster de banco de dados conforme necessário."
      opt3="Segurança"
      desc-opt3="Por padrão, clientes particulares têm protecção/segurança de mais alto nível com soluções de firewall NetSpace"
    ></component-description-service>
  </div>
</template>

<script>
import ComponentBannerService from "../../common/ComponentBannerService";
import ComponentDescriptionService from "../../common/ComponentDescriptionService";
export default {
  name: "ComponentDataBase",
  components: { ComponentDescriptionService, ComponentBannerService },
  data: () => ({
    dt: "background-image: linear-gradient(295deg,rgba(15, 15, 16, 0.68),rgb(0, 0, 0)),url('https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124648.jpg');background-size: cover;background-color: #efefef;",
  }),
};
</script>

<style scoped></style>
