import apiServices from "../../../services/apiServices";
import eventBus from "../../../../src/main";
import router from "../../../router";
import Vue from "vue";

export default {
  state: {
    pushMessage: "O seu registo foi realizado com sucesso!",
    country: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic (CAR)",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo, Democratic Republic of the",
      "Congo, Republic of the",
      "Costa Rica",
      "Cote d'Ivoire",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czechia",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      " El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini (formerly Swaziland)",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Pakistan",
      "Palau",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia (formerly Macedonia)",
      "Norway",
      "Oman",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (formerly Burma)",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Jamaica",
      "Japan",
      "Jordan",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Haiti",
      "Honduras",
      "Hungary",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (UAE)",
      "United Kingdom (UK)",
      "United States of America (USA)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City (Holy See)",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    province: [
      "Bengo",
      "Benguela",
      "Bié",
      "Cabinda",
      "Cuando-Cubango",
      "Cuanza Norte",
      "Cuanza Sul",
      "Cunene",
      "Huambo",
      "Huíla",
      "Luanda",
      "Lunda Norte",
      "Lunda Sul",
      "Malanje",
      "Moxico",
      "Namibe",
      "Uíge",
      "Zaire",
    ],
  },
  mutations: {},
  actions: {
    requestCallToNetSpace(context, payload) {
      eventBus.$emit("openSpin", true); // emit the event to the bus
      apiServices
        .requestCallToNetSpace(payload)
        .then(function (response) {
          if (response.status === 200) {
            eventBus.$emit("callMe", false); // emit the event to the bus
            return eventBus.$emit("responseRequest", true); // emit the event to the bus
          } else {
            Vue.toasted.error(
              "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
              {
                theme: "bubble",
                position: "top-center",
                duration: 5000,
              }
            );
          }
        })
        .catch(function () {
          Vue.toasted.error(
            "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
            {
              theme: "bubble",
              position: "top-center",
              duration: 5000,
            }
          );
        })
        .finally(function () {
          eventBus.$emit("openSpin", false); // emit the event to the bus
        });
    },

    requestService(context, payload) {
      eventBus.$emit("openSpin", true); // emit the event to the bus
      apiServices
        .requestInfoOfService(payload)
        .then(function (response) {
          if (response.status === 200) {
            return eventBus.$emit("responseRequest", true); // emit the event to the bus
          } else {
            Vue.toasted.error(
              "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
              {
                theme: "bubble",
                position: "top-center",
                duration: 5000,
              }
            );
          }
        })
        .catch(function () {
          Vue.toasted.error(
            "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
            {
              theme: "bubble",
              position: "top-center",
              duration: 5000,
            }
          );
        })
        .finally(function () {
          eventBus.$emit("openSpin", false); // emit the event to the bus
        });
    },


      requestRandom(context, payload) {
          eventBus.$emit("openSpin", true); // emit the event to the bus
          apiServices
              .requestRandomOfService(payload)
              .then(function (response) {
                  if (response.status === 200) {
                      let obj = response.data;
                      if (obj.session) {
                          apiServices.customizeHeader(obj.session);
                          localStorage.setItem("token", obj.session);
                          Vue.toasted.success("Acesso concedido!", {
                              theme: "bubble",
                              position: "top-center",
                              duration: 2000
                          });
                          return router.push("/add/view/customer").catch(() => {
                              throw new Error("Problem - Contact KP");
                          });
                      } else {
                          Vue.toasted.error(response.data.message, {
                              theme: "bubble",
                              position: "top-center",
                              duration: 4000
                          });
                      }
                  } else {
                      Vue.toasted.error(response.data.message, {
                          theme: "bubble",
                          position: "top-center",
                          duration: 4000
                      });
                  }
              })
              .catch(function () {
                  Vue.toasted.error(
                      "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
                      {
                          theme: "bubble",
                          position: "top-center",
                          duration: 5000,
                      }
                  );
              })
              .finally(function () {
                  eventBus.$emit("openSpin", false); // emit the event to the bus
              });
      },



      requestCharge(context, payload) {
          eventBus.$emit("openSpin", true); // emit the event to the bus
          apiServices
              .requestChargeService(payload)
              .then(function (response) {
                  if (response.status === 200) {
                      Vue.toasted.success(
                          "Pedido submetido com sucesso!",
                          {
                              theme: "bubble",
                              position: "top-center",
                              duration: 5000,
                          }
                      );
                  } else {
                      Vue.toasted.error(
                          "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
                          {
                              theme: "bubble",
                              position: "top-center",
                              duration: 5000,
                          }
                      );
                  }
              })
              .catch(function () {
                  Vue.toasted.error(
                      "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
                      {
                          theme: "bubble",
                          position: "top-center",
                          duration: 5000,
                      }
                  );
              })
              .finally(function () {
                  eventBus.$emit("openSpin", false); // emit the event to the bus
              });
      },
    //End Actions
  },

  getters: {},
};
