<template>
  <div
    class="bg-four-description hidden-sm-and-down"
    style="width: 100%; height: 450px; margin-top: -40px"
  >
    <v-container style="max-width: 80%" class="hidden-sm-and-down">
      <v-row class="fill-height mt-0" align="right" justify="right">
        <!--First Spoiler-->
        <v-col lg="12" md="auto" class="text-right">
          <v-card
            class="pa-2 text-right transparent"
            elevation="0"
            min-height="400"
            width="400"
            style="float: right; overflow: hidden"
          >
            <div class="spoiler-size call-wid text-right">
              <strong>Várias Velocidades</strong>
            </div>
            <v-card-text
              class="spoiler-description call-wid text-right"
              style="font-size: 24px !important"
            >
              Pode escolher o plano que se adequa às suas necessidades. Temos
              disponíveis velocidades de 1Mbps à 1Gbps
            </v-card-text>
            <v-card-text data-aos="fade-up">
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionFourDescription",
};
</script>

<style scoped>

.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
  text-align: right !important;
}

.bg-four-description {
  /*background-color: #efefef;*/
  background-color: #ffffff;
  /*background-image: url("https://cdn-gcpjb.nitrocdn.com/zDjUVtKwCNFRTvnNiXhNTehtxImYIiQK/assets/static/optimized/rev-cf99951/satenet/wp-content/uploads/sites/3/2019/12/background-with-dots.png");*/
  background-image: url("../../../assets/img/BG_net.jpg");
  background-size: cover;
  background-position: center;
}
</style>
