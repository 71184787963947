<template>
  <div>
    <header-mpls></header-mpls>
    <more-mpls></more-mpls>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderMpls from "../../components/cloud/mpls/HeaderMpls";
import MoreMpls from "../../components/cloud/mpls/MoreMpls";
import ContactFormService from "../../components/tools/ContactFormService";
export default {
  name: "PageMpls",
  components: { ContactFormService, MoreMpls, HeaderMpls },
};
</script>

<style scoped></style>
