<template>
  <div class="mb-12">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important">
            Escalabilidade horizontal ? Experimente o nosso Serviço em núvem
          </strong>
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Escale para a núvem e usufrua dos melhores serviços da SpaceCloud da NETSPACE.
          Proteja a sua rede local com a nossa Firewall como serviço (FWaaS) e Antivírus
          como serviço. Armazene dados crítcos em nossos serviços robustos de Database.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="sans spoiler-size call-wid">
              <strong>Cloud Firewall</strong>
            </div>
            <v-card-text
              class="spoiler-description call-wid"
              style="overflow-wrap: break-word !important"
            >
              Este serviço forma uma barreira virtual em torno de plataformas,
              infraestrutura e aplicativos em nuvem
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div class="">
                <v-btn @click="firewallInfo" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>Cloud Anti-Vírus</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Este serviço combina uma poderosa tecnologia de monitorização
              de Vírus e inibição imediata de ameaças.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="antiVirusInfo" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>Cloud Database</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Instâncias poderosas sob cloud computing para leitura e escrita de dados que exigem alta performance,
              SLA e mobilidade.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="databaseInfo" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div style="margin-top: 80px"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionEnterpriseB",
  methods: {
    firewallInfo() {
      return this.$store.dispatch("firewallInfo");
    },
    antiVirusInfo() {
      return this.$store.dispatch("antiVirusInfo");
    },
    databaseInfo() {
      return this.$store.dispatch("databaseInfo");
    },
  },
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}
.draw-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-vector/data-protection-background-vector-cyber-security-technology-purple-tone_53876-136341.jpg");
  background-position: 0 75%;
  background-size: cover;

}
.office-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-vector/isometric-shield-security-safe-privacy-concept-abstract-low-poly-design-vector-illustration_587448-458.jpg");
  background-size: cover;
  background-position: 0%;
}

.ofmail-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124648.jpg");
  background-size: cover;
}
</style>
