<template>
  <div>
    <header-video></header-video>
    <header-content></header-content>
    <section-second-plan></section-second-plan>
    <section-four-description></section-four-description>
    <section-third-plan></section-third-plan>
    <section-cyber-spoiler></section-cyber-spoiler>
    <call-me></call-me>
  </div>
</template>

<script>
import HeaderVideo from "../components/particular/header/HeaderVideo";
import HeaderContent from "../components/particular/header/HeaderContent";
import SectionSecondPlan from "../components/particular/section/SectionSecondPlan";
import SectionThirdPlan from "../components/particular/section/SectionThirdPlan";

import SectionFourDescription from "../components/particular/section/SectionFourDescription";

import SectionCyberSpoiler from "../components/particular/section/SectionCyberSpoiler";
import CallMe from "../components/tools/CallMe";
export default {
  name: "PageSingleCustomer",
  components: {
    CallMe,
    SectionCyberSpoiler,

    SectionFourDescription,

    SectionThirdPlan,
    SectionSecondPlan,
    HeaderContent,
    HeaderVideo,
  },
  metaInfo: {
    title: "NetSpace, Internet, Cloud",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Internet em angola. Cloud, VPN, VPS e Telefonia",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
