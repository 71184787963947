<template>
  <div class="mb-12 mt-n6 bg-more-support" style="width: 100%">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important"
            >Mais sobre o serviço Web E-mail</strong
          >
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          O serviço de VPN da Netspace é uma solução altamente segura e
          resistente para redes privadas virtuais, baseada em IP que permite aos
          clientes empresariais ou particular construir a sua própria
          Intranet/Extranet entre as sedes, filiais e parceiros de negócio.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="sans spoiler-size call-wid">
              <strong>Disponibilidade</strong>
            </div>
            <v-card-text
              class="spoiler-description call-wid"
              style="overflow-wrap: break-word !important"
            >
              Inclui SLA com disponibilidade de 99,995%, e a possibilidade de
              definir soluções de redundância à medida
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>Mobilidade</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Facilidade de acesso. Aceda aos seus e-mails a prtir de qualquer lugar.
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>Segurança</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Por padrão, clientes particulares têm protecção/segurança de mais
              alto nível com soluções de firewall NetSpace
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class=""></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MoreEmail",
  methods: {},
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(23, 87, 109) !important;
  line-height: 1.2;
}

.bg-more-support {
  background-color: #ffffff;
}
</style>
