<template>
  <div style="height: 400px !important; margin-top: -980px">
    <video
      style="
        min-height: 100%;
        height: 830px;
        min-width: 100%;
        position: relative;
      "
      playsinline
      autoplay
      muted
      loop
    >
      <source
        src="../../../assets/video/video_particular.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
export default {
  name: "HeaderVideo",
};
</script>

<style scoped>
/*background-color: #2d2b2b;*/
</style>
