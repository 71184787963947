<template>
<div>
  <header-net-sat>
  </header-net-sat>
  <more-net-sat></more-net-sat>
  <contact-form-service></contact-form-service>
</div>
</template>

<script>
import HeaderNetSat from "../components/internet/netsat/HeaderNetSat";
import MoreNetSat from "../components/internet/netsat/MoreNetSat";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetSat",
  components: {ContactFormService, MoreNetSat, HeaderNetSat},
  metaInfo: {
    title: "NETSAT - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "NETSAT - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Serviço que suporta tráfego bidireccional. Orientado para o fornecimento do acesso via-satélite à Internet, VPNs.",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
}
</script>

<style scoped>

</style>