export default {
  state: {
    pointChar: "",
  },
  mutations: {
    SET_FLY_NEXT(state, value) {
      state.pointChar = value;
    },
  },
  actions: {
    rubyOnRile(context) {
      const random = () => {
        const text = [
          "ADDSYS",
          "DELMAWAVE",
          "SYSUNDROP",
          "SIRENE",
          "BREAKPOINT",
          "KYAMIPAY",
          "GREATPAY",
          "IHORNI",
          "CATINFOR",
        ];
        let arr = Math.floor(Math.random() * 8);
        context.commit("SET_FLY_NEXT", text[arr]);
      };
      return random();
    },
  },
  getters: {
    getNextR(state) {
      return state.pointChar;
    },
  },
};
