<template>
  <div class="bg-header-inst" style="height: 400px; margin-top: -738px">
    <v-container>
      <v-card
        width="320"
        class="transparent"
        elevation="0"
        style="padding-top: 100px !important"
      >
        <h1 class="">
          <span class="mx-auto text-center text-header white--text text-left"
            >Conheça Melhor a NetSpace</span
          >
        </h1>
      </v-card>

      <v-card-text
        style="width: 400px; font-size: 20px !important"
        class="mt-12 white--text text-subtitle-1 text-sub-header margin-line font-weight-regular mx-n4"
      >
        Inovação, Tecnologia e Compromisso
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeaderBanner",
};
</script>

<style scoped>

.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}
.bg-sec-support-e {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  /*line-height: 1;*/
  font-size: 28px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}

.bg-header-inst {
  background-image: linear-gradient(
      295deg,
      rgba(15, 15, 16, 0.68),
      rgb(0, 0, 0)
    ),
    url("../../../assets/img/img_2.png");
  background-size: cover;
  background-color: #efefef;
}
</style>
