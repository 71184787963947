import router from "../../../router";

export default {
  state: {
    homePage: {
      urlStringAccount: "/create/webpage/choose",
      urlStringMore: "/webpage/personal",
    },
    networks: {
      linkedin: "",
      instagram: "",
      youtube: "",
      facebook: "",
    },
  },
  mutations: {},
  actions: {
    particular() {
      return router.push("/");
    },
    enterprise() {
      return router.push("/enterprise");
    },
    institutional() {
      return router.push("/institutional");
    },
    mplsInfo() {
      return router.push({ name: "enterprise.cloud.mpls" });
    },
    emailHosting() {
      return router.push({ name: "enterprise.cloud.email" });
    },

    iaas() {
      return router.push({ name: "enterprise.cloud.iaas" });
    },
    webHosting() {
      return router.push({ name: "enterprise.cloud.web.hosting" });
    },
    firewallInfo() {
      return router.push({ name: "enterprise.cloud.firewall" });
    },
    antiVirusInfo() {
      return router.push({ name: "enterprise.cloud.antivirus" });
    },
    databaseInfo() {
      return router.push({ name: "enterprise.cloud.database" });
    },

    netFast() {
      return router.push({ name: "internet.netfast" });
    },
    netSat() {
      return router.push({ name: "internet.netsat" });
    },
    netPremium() {
      return router.push({ name: "internet.netpremium" });
    },
    voip() {
      return router.push({ name: "enterprise.voip" });
    },
    sipTrunk() {
      return router.push({ name: "enterprise.sip.trunk" });
    },
    ipPbx() {
      return router.push({ name: "enterprise.pbx" });
    },
    myAccount() {
      return window.open("https://portal.netspace.co.ao", "_blank");
    },
          dashboard() {
    return window.open("http://graph.netspace.co.ao", "_blank");
},

    linkedin() {
      return window.open(
        "https://www.linkedin.com/company/netspace-angola",
        "_blank"
      );
    },
    instagram() {
      return window.open("https://www.instagram.com/netspace_", "_blank");
    },
    youtube() {
      return window.open("https://www.linkedin.com/company/18246974", "_blank");
    },
    facebook() {
      return window.open(
        "https://www.facebook.com/profile.php?id=100063302404916",
        "_blank"
      );
    },

    //End Actions
  },
  getters: {
    getUrlcreateAccount(state) {
      return state.homePage.urlStringAccount;
    },
    getUrlMoreDetailsPersonal(state) {
      return state.homePage.urlStringMore;
    },
  },
};
