/**
 * Next Reload JS
 * Development By Délcio Cabanga
 * Copyright: SYS FACTORY
 * Created: 14/08/2020
 * Version: 1.0
 *
 * Project supported by Companies:
 *                 SYS FACTORY ****************************
 *                 DELMAWAVE   ****************************
 * Luanda - Angola
 */

import { Character } from "./Character.js";
import { QuestionMath } from "./QuestionMath.js";
import { Language } from "./i18n/Language";
import { Canva } from "./Canva";
import store from "../../src/store";

//Set the Classes
let character = new Character();
let lang = new Language();
let qMath = new QuestionMath(lang);
let canvas = new Canva();

//Get the objects
const question = JSON.parse(qMath.renderResult());
const alpha = character.rendResult();

/**
 * The Next Reload JS Core
 */

let nextVar;
let rand;

const choose = [alpha.join(""), question.form];

//Best Way

// (function f() {
//   nextVar = choose[Math.floor(Math.random() * 2)];
//   canvas.creatCanvas(nextVar);
// })();

export class NextReload {
  render() {
    rand = Math.floor(Math.random() * 2);
    if (rand === 0) {
      nextVar = choose[rand];
      store.commit("SET_NEXT_RELOAD", nextVar);
      return canvas.creatCanvas(nextVar);
    } else {
      nextVar = choose[1];
      store.commit("SET_NEXT_RELOAD", atob(question.a));
      return canvas.creatCanvas(nextVar);
    }
    // nextVar = choose[Math.floor(Math.random() * 2)];
    // console.log(nextVar);
    // canvas.creatCanvas(nextVar);
  }
}
