<template>
  <div class="mb-12">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important">
            Produtos premium de conectividade estáveis.
          </strong>
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Escolha como pretende conectar-se. À velocidade da luz, microondas ou
          mesmo via satélite. Os nossos produtos garantem ao cliente uma
          conectividade estável para que possa aproveitar ao máximo as
          oportunidades que surgem.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4" class="parent">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="sans spoiler-size call-wid">
              <strong>NetFast</strong>
            </div>
            <v-card-text
              class="spoiler-description call-wid"
              style="overflow-wrap: break-word !important"
            >
              Serviço de Internet fiável e seguro, de alta velocidade terminado
              em tecnologia de fibra até o equipamento do Cliente.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div class="">
                <v-btn @click="netFast" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>NetSat</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Serviço que suporta tráfego bidireccional. Orientado para o
              fornecimento do acesso via-satélite à Internet, VPNs.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="netSat" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiler-->
        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <div class="spoiler-size call-wid">
              <strong>NetPremium</strong>
            </div>
            <v-card-text class="spoiler-description call-wid">
              Serviço de internet que utiliza a tecnologia de microondas para
              conectar-se via uma antena exterior.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="netPremium" large dark>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div style="margin-top: 40px"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionSecondPlan",
  methods: {
    netFast() {
      // return this.$router.push({ name: "articles.edit", params: { id: id } })
      return this.$router.push({ name: "internet.netfast" });
    },
    netSat() {
      return this.$router.push({ name: "internet.netsat" });
    },
    netPremium() {
      return this.$router.push({ name: "internet.netpremium" });
    },
  },
};
</script>

<style scoped>
.zoom {
  transition: transform 0.2s; /* Animation */
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}
.draw-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/beautiful-girl-with-afro-hair_53876-89031.jpg");
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  background-position: 30% 75%;
  background-size: cover;
  /*background-position: 50% 226%;*/
  /*background-position: 85% -68%;    */
}

.draw-svg:hover,
.office-svg:hover,
.office-svg:focus,
.ofmail-svg:hover,
.ofmail-svg:focus .draw-svg:focus {
  transform: scale(1.02);
  transition: transform 0.2s; /* Animation */
}

.office-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-vector/spacecraft-realistic-background_1284-72905.jpg");
  background-size: cover;
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  /*background-position: 25% 75%;*/
  /*background-position: 50% 226%;*/
  /*background-position: 85% -68%;    */
  /*background-position: -36% -126%;*/
}

.ofmail-svg {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/african-american-man-customer-support-operator-with-hands-free-headset-working-office_231208-686.jpg");
  background-size: cover;
  /*, linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5));*/
  /*background-position: 25% 75%;*/
  /*background-position: 50% 226%;*/
  /*background-position: 85% -68%;    */
  /*background-position: -36% -126%;*/
}
</style>
