<template>
  <v-overlay :value="overlay" style="z-index: 999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "Spin",
  // props: {
  //   overlay: {
  //     type: Boolean,
  //     require: true
  //   }
  // },
  data: () => ({
    overlay: false,
  }),
  created() {
    const self = this;
    eventBus.$on("openSpin", function (overlay) {
      self.overlay = overlay;
    });
  },
};
</script>
