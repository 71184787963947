<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-container>
            <v-card-text class="m-txt-center"
              ><v-icon size="96" color="green"
                >mdi-check-circle-outline</v-icon
              ></v-card-text
            >
            <v-card-text class="m-txt-center">Seu pedido foi realizado com sucesso!</v-card-text>
          </v-container>
          <!--          <small>*indicates required field</small>-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="rgb(23, 87, 109)" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "ResponseRequest",
  data: () => ({
    dialog: false,
    valid: true,
    name: "",
    email: "",
    phone: "",
    opt: "",
    nameRules: [
      (v) => v.length <= 60 || "Máximo 10 caracteres",
      (v) => !!v || "Campo obrigatório",
    ],

    phoneRules: [
      (v) => v.length <= 9 || "Máximo 9 caracteres",
      (v) => /^\d+$/.test(v) || "Campo obrigatório",
    ],
    emailRules: [
      (v) => v.trim().split(" ").length <= 30 || "Máximo 30 caracteres",
      (v) => /.+@.+\..+/.test(v) || "Correio inválido",
      (v) => !!v || "Campo obrigatório",
    ],
  }),
  methods: {
  },
  created() {
    const self = this;
    eventBus.$on("responseRequest", function (payload) {
      self.dialog = payload;
    });
  },
};
</script>

<style scoped></style>
