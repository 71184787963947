<template>
  <div>
    <header-voip></header-voip>
    <more-voip></more-voip>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderVoip from "../components/voice/voip/HeaderVoip";
import MoreVoip from "../components/voice/voip/MoreVoip";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageVoip",
  components: { ContactFormService, MoreVoip, HeaderVoip },
  metaInfo: {
    title: "Voip - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "Voip - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Serviço de Voz de alta Qualidade de ponta a ponta, que permite ao cliente empresarial ou particular fazer e receber chamadas a um custo baixo.",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
