<template>
  <div>
    <component-banner-service
      title="Conheça o serviço"
      bold-title="Cloud Anti-vírus"
      description="Este serviço combina uma poderosa tecnologia de monitorização de Vírus e inibição imediata de ameaças."
      :my-style="dt"
    ></component-banner-service>
    <component-description-service
      title="Mais sobre o serviço Cloud Anti-vírus"
      description="O nosso serviço de anti-vírus em nuvem usa uma variedade de técnicas para detecção e inibição de variadíssimas ameças."
      opt1="Prevenção"
      desc-opt1="proteja seus dados contra malware e outros ataques, que representam ameaças significativas à sua Empresa."
      opt2="Sem Falsos Positivos"
      desc-opt2="A nossa solução mantém uma base dados actualizada de arquivos seguros conhecidos e seus padrões de comportamento"
      opt3="Acessibilidade"
      desc-opt3="Aceda remotamente através de PCs, Smartphones e tablets sem qualquer necessidade de instalação local em cada dispositivo"
    ></component-description-service>
  </div>
</template>

<script>
import ComponentBannerService from "../../common/ComponentBannerService";
import ComponentDescriptionService from "../../common/ComponentDescriptionService";
export default {
  name: "ComponentAntiVirus",
  components: { ComponentDescriptionService, ComponentBannerService },
  data: () => ({
    dt: "background-image: linear-gradient(295deg,rgba(15, 15, 16, 0.68),rgb(0, 0, 0)),url('https://img.freepik.com/free-vector/isometric-shield-security-safe-privacy-concept-abstract-low-poly-design-vector-illustration_587448-458.jpg');background-size: cover;background-color: #efefef;",
  }),
};
</script>

<style scoped></style>
