<template>
  <div>
    <header-banner></header-banner>
    <option-navigate-inst></option-navigate-inst>
<!--    <section-title-description></section-title-description>-->
    <section-institutional-a></section-institutional-a>
  </div>
</template>

<script>
import HeaderBanner from "../components/institucional/header/HeaderBanner";
import OptionNavigateInst from "../components/institucional/section/OptionNavigateInst";
import SectionInstitutionalA from "../components/institucional/section/SectionInstitutionalA";

export default {
  name: "PageInstitutional",
  components: {SectionInstitutionalA, OptionNavigateInst, HeaderBanner},
  metaInfo: {
    title: "NetSpace - Intitucional",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "Intitucional - NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Internet 4G em angola. Cloud, VPN, VPS e Telefonia",
      },
    ],
  },
};
</script>

<style scoped></style>
