<template>
  <v-app id="app">
    <div>
      <!--MENU-->
      <primary-menu></primary-menu>
      <secundary-menu></secundary-menu>
      <v-main>
        <v-fade-transition name="fade" mode="out-in">
          <router-view />
          <page-single-customer></page-single-customer>
        </v-fade-transition>
      </v-main>
      <!--      Footer-->
      <section-more-support></section-more-support>
      <section-contact></section-contact>
      <!--      <section-copy></section-copy>-->
      <spin></spin>
      <response-request></response-request>
    </div>
  </v-app>
</template>

<script>
import PrimaryMenu from "../components/menus/PrimaryMenu";
import SecundaryMenu from "../components/menus/SecundaryMenu";
import SectionMoreSupport from "../components/particular/section/SectionMoreSupport";
import SectionContact from "../components/particular/section/SectionContact";

import PageSingleCustomer from "./PageSingleCustomer";
import Spin from "../components/tools/Spin";
import ResponseRequest from "../components/tools/ResponseRequest";
export default {
  name: "Aplication",
  components: {
    ResponseRequest,
    Spin,
    PageSingleCustomer,

    SectionContact,
    SectionMoreSupport,
    SecundaryMenu,
    PrimaryMenu,
  },
  created() {
    // this.$toasted.show(
    //   "Caro Visitante, informamos que o nosso site continua em actualizações",
    //   {
    //     theme: "bubble",
    //     position: "top-center",
    //     duration: 5000,
    //   }
    // );
  },
};
</script>

<style scoped></style>
