<template>
  <div class="mb-12 mt-n6 bg-more-support" style="width: 100%; min-height: 400px">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important">A Netspace</strong>
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Operador de Telecomunicações - Internet: Trazemos um novo conceito de
          comunicação
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <v-carousel
          cycle
          style="min-height: 380px"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item>
            <v-sheet min-height="98%">
              <v-row class="fill-height" align="center" justify="center">
                <v-col lg="8">
                  <v-card
                    class="pa-2 draw-svg text-lef transparent"
                    elevation="0"
                    min-height="400"
                  >
                    <!--                    <div class="sans spoiler-size call-wid">-->
                    <!--                      <strong>Quem Somos</strong>-->
                    <!--                    </div>-->
                    <v-card-text
                      class="spoiler-description call-wid"
                      style="overflow-wrap: break-word !important"
                    >
                      Netspace é uma empresa privada de direito angolano, que
                      actua no mercado das telecomunicações, pretende tornar-se
                      um player de nicho no mercado de Internet, VPN e Voz e
                      posicionar-se como um prestador de serviços diferenciados,
                      abrangentes e de elevado valor no mercado angolano das
                      telecomunicações. Comercializar serviços de comunicações
                      modernas de excelência desenhadas à medida de cada
                      cliente, maximizando valor para todos os stakeholders, e
                      contribuindo para o desenvolvimento sustentado das
                      telecomunições em Angola.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item style="min-height: 400px; height: auto">
            <v-sheet min-height="98%">
              <v-row class="fill-height" align="center" justify="center">
                <v-col lg="4">
                  <v-card
                    class="pa-4 draw-svg text-left transparent"
                    elevation="0"
                    min-height="400"
                  >
                    <div class="sans spoiler-size call-wid">
                      <strong>Missão</strong>
                    </div>
                    <v-card-text
                      class="spoiler-description call-wid"
                      style="overflow-wrap: break-word !important"
                    >
                      Ser a opção de comunicação para o segmento
                      corporativo, estatal e público, entregando soluções
                      com alta qualidade, com eficiência e eficácia,
                      levando o cliente ao alcance dos seus objectivos,
                      contribuindo assim para o desenvolvimento
                      sócio-económico de Angola.
                    </v-card-text>
                  </v-card>
                </v-col>

                <!--Second Spoiller-->

                <v-col lg="4" md="auto">
                  <v-card
                    class="pa-2 office-svg m-txt-center transparent"
                    elevation="0"
                    min-height="400"
                  >
                    <div class="spoiler-size call-wid">
                      <strong>Valores</strong>
                    </div>
                    <v-card-text class="spoiler-description call-wid">
                      Integridade, Respeito, Diamismo, Transaparência e ética profissional.
                    </v-card-text>
                  </v-card>
                </v-col>


                <!--Third Spoiller-->
                <v-col lg="4" md="auto" class="">
                  <v-card
                    class="pa-4 ofmail-svg text-right transparent "
                    elevation="0"
                    min-height="400"
                  >
                    <div class="spoiler-size call-wid">
                      <strong>Visão</strong>
                    </div>
                    <v-card-text class="spoiler-description call-wid">
                      Ser um operador reconhecido como inovador e diferenciado no mercado
                      Angolano, na entrega de soluções de comunicação e tecnologias de informação.
                    </v-card-text>
                    <!--            <v-card-text data-aos="fade-up">-->
                    <!--              <div>-->
                    <!--                <v-btn @click="knowMoreEnterprise" large>Saiba mais</v-btn>-->
                    <!--              </div>-->
                    <!--            </v-card-text>-->
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <!--                        <v-col lg="4">-->
        <!--                          <v-card-->
        <!--                            class="pa-2 draw-svg m-txt-center"-->
        <!--                            color="#ffffff"-->
        <!--                            elevation="0"-->
        <!--                            min-height="400"-->
        <!--                          >-->
        <!--                            <div class="sans spoiler-size call-wid">-->
        <!--                              <strong>Disponibilidade</strong>-->
        <!--                            </div>-->
        <!--                            <v-card-text-->
        <!--                              class="spoiler-description call-wid"-->
        <!--                              style="overflow-wrap: break-word !important"-->
        <!--                            >-->
        <!--                              Inclui SLA com disponibilidade de 99,995%, e a possibilidade de-->
        <!--                              definir soluções de redundância à medida-->
        <!--                            </v-card-text>-->
        <!--                          </v-card>-->
        <!--                        </v-col>-->

        <!--                &lt;!&ndash;Second Spoiller&ndash;&gt;-->

        <!--                <v-col lg="4" md="auto">-->
        <!--                  <v-card-->
        <!--                    class="pa-2 office-svg m-txt-center"-->
        <!--                    color="#ffffff"-->
        <!--                    elevation="0"-->
        <!--                    min-height="400"-->
        <!--                  >-->
        <!--                    <div class="spoiler-size call-wid">-->
        <!--                      <strong>Velocidade</strong>-->
        <!--                    </div>-->
        <!--                    <v-card-text class="spoiler-description call-wid">-->
        <!--                      Acesso dedicado à Internet com largura de banda garantida e-->
        <!--                      simétrica de até 1Gbps, sem contenção.-->
        <!--                    </v-card-text>-->
        <!--                  </v-card>-->
        <!--                </v-col>-->
        <!--                &lt;!&ndash;Third Spoiller&ndash;&gt;-->
        <!--                <v-col lg="4" md="auto">-->
        <!--                  <v-card-->
        <!--                    class="pa-2 ofmail-svg m-txt-center"-->
        <!--                    color="#ffffff"-->
        <!--                    elevation="0"-->
        <!--                    min-height="400"-->
        <!--                  >-->
        <!--                    <div class="spoiler-size call-wid">-->
        <!--                      <strong>Segurança</strong>-->
        <!--                    </div>-->
        <!--                    <v-card-text class="spoiler-description call-wid">-->
        <!--                      Por padrão, clientes particulares têm protecção/segurança de mais-->
        <!--                      alto nível com soluções de firewall NetSpace-->
        <!--                    </v-card-text>-->
        <!--                    &lt;!&ndash;            <v-card-text data-aos="fade-up">&ndash;&gt;-->
        <!--                    &lt;!&ndash;              <div>&ndash;&gt;-->
        <!--                    &lt;!&ndash;                <v-btn @click="knowMoreEnterprise" large>Saiba mais</v-btn>&ndash;&gt;-->
        <!--                    &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--                    &lt;!&ndash;            </v-card-text>&ndash;&gt;-->
        <!--                  </v-card>-->
        <!--                </v-col>-->
      </v-row>
      <div class=""></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "OptionNavigateInst",
  methods: {
    knowMore() {
      return this.$store.dispatch("particular");
    },
    knowMoreEnterprise() {
      return this.$store.dispatch("enterprise");
    },
    urlStringAccount() {
      return this.$store.dispatch("createAccount");
    },
  },
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(23, 87, 109) !important;
  line-height: 1.2;
}

.bg-more-support {
  background-color: #ffffff;
}
</style>

<!--<template>-->
<!--&lt;!&ndash;  <v-bottom-navigation&ndash;&gt;-->
<!--&lt;!&ndash;    :value="value"&ndash;&gt;-->
<!--&lt;!&ndash;    color="rgb(23, 87, 109)"&ndash;&gt;-->
<!--&lt;!&ndash;    horizontal&ndash;&gt;-->
<!--&lt;!&ndash;    height="80"&ndash;&gt;-->
<!--&lt;!&ndash;    style="box-shadow: none !important;"&ndash;&gt;-->
<!--&lt;!&ndash;  >&ndash;&gt;-->
<!--&lt;!&ndash;    <v-btn style="font-size: 16px !important;">&ndash;&gt;-->
<!--&lt;!&ndash;      <span class="sans">Tudo</span>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-icon>mdi-format-list-text</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;    </v-btn>&ndash;&gt;-->

<!--&lt;!&ndash;    <v-btn style="font-size: 16px !important;">&ndash;&gt;-->
<!--&lt;!&ndash;      <span class="sans">Quem somos</span>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-icon>mdi-home</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;    </v-btn>&ndash;&gt;-->

<!--&lt;!&ndash;    <v-btn style="font-size: 16px !important;">&ndash;&gt;-->
<!--&lt;!&ndash;      <span class="sans">Imprensa</span>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-icon>mdi-newspaper</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;    </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;  </v-bottom-navigation>&ndash;&gt;-->
<!--  <div></div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "OptionNavigateInst",-->
<!--  data: () => ({ value: 0 }),-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--.sans {-->
<!--  font-family: "Fira Sans Condensed", sans-serif !important;-->
<!--  /*font-family: "Source Sans Pro", sans-serif;*/-->
<!--  /*color: #3e3e42;*/-->
<!--  color: #32363b;-->
<!--  line-height: 1.2;-->
<!--}-->
<!--</style>-->
