<template>
  <div>
    <header-pbx></header-pbx>
    <more-pbx></more-pbx>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderPbx from "../components/voice/pbx/HeaderPbx";
import MorePbx from "../components/voice/pbx/MorePbx";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageIPBX",
  components: {ContactFormService, MorePbx, HeaderPbx },
  metaInfo: {
    title: "IP PBX  - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "IP PBX - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Serviço com recursos de última geração, totalmente online em cloud computing e com a praticidade que se precisa.",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
