import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import AOS from "aos";
import "@/assets/css/modularCSS.css";
// import "@/assets/css/fontawesome.min.css";
// import "@/assets/css/ao-animated.css";
import VueMeta from "vue-meta";
import Toasted from "vue-toasted";
import vuelidate from "vuelidate";
import apiServices from "./services/apiServices";

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(Toasted);
Vue.use(vuelidate);

const eventBus = new Vue();
export default eventBus;

new Vue({
  router,
  store,
  created() {
      const userToken = localStorage.getItem("token");
      apiServices.customizeHeader(userToken);
      apiServices.interceptionRoute();
    AOS.init();
  },
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
