<template>
  <div>
    <header-cover></header-cover>
    <header-content-cover></header-content-cover>
    <section-enterprise-a></section-enterprise-a>
    <section-support></section-support>
    <section-enterprise-b></section-enterprise-b>
    <section-paas></section-paas>
  </div>
</template>

<script>
import HeaderCover from "../components/enterprise/header/HeaderCover";
import HeaderContentCover from "../components/enterprise/header/HeaderContentCover";
import SectionEnterpriseA from "../components/enterprise/section/SectionEnterpriseA";
import SectionSupport from "../components/enterprise/section/SectionSupport";
import SectionEnterpriseB from "../components/enterprise/section/SectionEnterpriseB";
import SectionPaas from "../components/enterprise/section/SectionPaas";
export default {
  name: "PageEnterprise",
  components: {
    SectionPaas,
    SectionEnterpriseB,
    SectionSupport,
    SectionEnterpriseA,
    HeaderContentCover,
    HeaderCover,
  },
  metaInfo: {
    title: "NetSpace - Empresas",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "Empresas - NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Internet em angola. Cloud, VPN, VPS e Telefonia",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
