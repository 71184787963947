<template>
  <div>
    <header-net-premium></header-net-premium>
    <more-net-premium></more-net-premium>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderNetPremium from "../components/internet/premium/HeaderNetPremium";
import MoreNetPremium from "../components/internet/premium/MoreNetPremium";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetPremium",
  components: { ContactFormService, MoreNetPremium, HeaderNetPremium },
  metaInfo: {
    title: "NETPREMIUM - Internet, Cloud, VPN e VPS | NetSpace",
    // titleTemplate: '%s | NetSpace',
    titleTemplate: "NETPREMIUM - Internet, Cloud, VPN e VPS | NetSpace",
    htmlAttrs: {
      lang: "pt-AO",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Serviço de internet que utiliza a tecnologia de microondas para conectar-se via uma antena exterior.",
      },
      // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
  },
};
</script>

<style scoped></style>
