<template>
  <div class="mb-12 bg-sec-support-e" style="margin-top: -50px">
    <div style="width: 100%">
      <v-row
        class="fill-height mt-12"
        align="center"
        justify="center"
        no-gutters
      >
        <!--First Spoiller-->

        <v-col lg="6">
          <div style="min-height: 600px" class="pa-2 bg-support m-txt-center">
            <div class="bg-support"></div>
          </div>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="6">
          <div style="min-height: 600px; background-color: #235db5; padding: 50px">
            <div class="sans spoiler-size call-wid">
              <strong style="color: white !important"
                >Desfrute do nosso Apoio ao Cliente de mais alto padrão</strong
              >
            </div>

            <v-card-text class="spoiler-description call-wid">
              Todos os dias desenvolvemos técnicas para estar
              entre as melhores equipas de atendimento e suporte online.
            </v-card-text>
            <v-card-text class="spoiler-description call-wid">
              Disponível 24/7
            </v-card-text>
          </div>
        </v-col>
        <!--Third Spoiller-->
      </v-row>
      <div style="margin-top: -25px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionSupport",
};
</script>

<style scoped>
.bg-sec-support-e {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  /*line-height: 1;*/
  font-size: 28px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}

.bg-support {
  background-image: url("https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  background-color: #efefef;
}

</style>
