<template>
  <div class="mb-12 mt-n6 bg-more-support" style="width: 100%">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important"
            >Solicite Orçamento</strong
          >
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <v-col cols="12">
          <v-row sm="4" align="center" justify="center">
            <v-card elevation="0" width="95%" class="transparent">
              <v-form ref="form" v-model="valid" novalidate id="formReq">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Digite seu nome"
                      filled
                      dense
                      name="name"
                      v-model="name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Digite o seu e-mail"
                      filled
                      dense
                      name="email"
                      v-model="email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      filled
                      label="Número de telefone"
                      dense
                      v-model="phone"
                      name="phone"
                      :rules="phoneRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      filled
                      label="Nome da empresa"
                      dense
                      v-model="company"
                      name="company"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      filled
                      label="Preencha com o resultado da imagem abaixo"
                      dense
                      v-model="captcha"
                      :rules="captchaRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      filled
                      label="Mensagem"
                      dense
                      v-model="message"
                      name="message"
                      :rules="messengerRules"
                    ></v-text-field>
                  </v-col>

                  <input type="hidden" name="subject" :value="text" />
                  <input type="hidden" name="service" :value="service" />

                  <v-card-text id="n-reload"> </v-card-text>
                  <v-card-text style="margin-top: -50px">
                    <v-btn
                      class="m-btn-effect m-btn-defaul-border m-txt-center"
                      text
                      outlined
                      block
                      color="rgb(23, 87, 109)"
                      style="text-transform: capitalize"
                      @click="sendProp()"
                      :disabled="!valid"
                    >
                      Submeter
                    </v-btn>
                  </v-card-text>
                </v-row>
              </v-form>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <div class=""></div>
    </v-container>
  </div>
</template>

<script>
import { NextReload } from "../../services/nextReload";
import Vue from "vue";

export default {
  name: "ContactFormService",
  data: () => ({
    text: "",
    service: "",
    valid: true,
    name: "",
    message: "",
    messengerRules: [
      (v) => !!v || "Mensagem é reuqerido",
      (v) =>
        (v && v.length >= 5) || "Mensagem deve ter pelomenos de 5 caracteres",
    ],
    nameRules: [
      (v) => !!v || "Nome é reuqerido",
      (v) =>
        (v && v.length >= 10) || "Nome deve ter pelomenos de 10 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail é requerido",
      (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
    ],
    company: "",
    companyRules: [
      (v) => !!v || "Nome da empresa é requerido",
      (v) => (v && v.length >= 3) || "Empresa deve ter pelomenos 3 caracteres",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Telefone é requerido",
      (v) => (v && v.length === 9) || "Telefone é requerido : Ex: 924xxxyyy",
    ],
    captcha: "",
    captchaRules: [(v) => !!v || "Captcha é requerido"],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      return this.$refs.form.reset();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    nextReaload() {
      let next;
      next = new NextReload();
      return next.render();
    },
    sendProp() {
      document.getElementById("n-reload").innerHTML = "";
      if (this.$store.getters.getNs === this.captcha) {
        if (this.validate()) {
          let Form = document.getElementById("formReq");
          let formData = new FormData(Form);
          let data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, { [key]: val });
          }
          return this.$store.dispatch("requestService", data);
        } else {
          document.getElementById("n-reload").innerHTML = "";
          Vue.toasted.error(
            "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
            {
              theme: "bubble",
              position: "top-center",
              duration: 5000,
            }
          );
          this.nextReaload();
        }
      }
      Vue.toasted.error(
        "Lamentamos, ocorreu algum erro. Tente novamente mais trade.",
        {
          theme: "bubble",
          position: "top-center",
          duration: 5000,
        }
      );
      document.getElementById("n-reload").innerHTML = "";
      this.nextReaload();
    },
  },
  mounted() {
    const getLastItem = (Path) => Path.substring(Path.lastIndexOf("/") + 1);
    getLastItem(window.location.pathname);
    this.text = 'Solicitação do Serviço ' + getLastItem(window.location.pathname).toUpperCase();
    this.service = getLastItem(window.location.pathname).toUpperCase();
    return this.nextReaload();
  },
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(23, 87, 109) !important;
  line-height: 1.2;
}

.bg-more-support {
  background-color: #ffffff;
}
</style>
